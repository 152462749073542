import {get, post, put,Delete} from '../axios'
// 角色管理-列表
export const roleList = params => get('/web/system/role/list',params);

// 角色管理-新增
export const roleAdd = params => post('/web/system/role/add',params);

// 角色管理-查看
export const roleView = params => get(`/web/system/role/view/${params}`);

// 角色管理-修改
export const roleUpdate = params => put('/web/system/role/update',params);

// 角色管理-删除
export const roleDelete = params => Delete(`/web/system/role/delete/${params}`);

// 角色管理-菜单树形结构
export const roleMenuTree = params => get('/web/system/role/menu/tree',params);

// 角色管理-查看角色菜单
export const roleMenuView = params => get(`/web/system/role/menu/view/${params}`);

// 角色管理-角色菜单修改
export const roleMenuUpdate = params => put('/web/system/role/menu/update',params);

/**
 * 
 * 
 * 
 * 企业角色管理
 * 
 * 
 */

/* 角色管理 - 列表*/
export const systemRoleList = params =>  get('/platform/system/role/list',params);

// 角色管理-新增
export const systemRoleAdd = params => post('/platform/system/role/add',params);

// 角色管理-查看
export const systemRoleView = params => get(`/platform/system/role/view/${params}`);

// 角色管理-修改
export const systemRoleUpdate = params => put('/platform/system/role/update',params);

// 角色管理-删除
export const systemRoleDelete = params => Delete(`/platform/system/role/delete/${params}`);

// 角色管理-菜单树形结构
export const systemRoleMenuTree = params => get('/platform/system/role/menu/tree',params);

// 角色管理-查看角色菜单
export const systemRoleMenuView = params => get(`/platform/system/role/menu/view/${params}`);

// 角色管理-角色菜单修改
export const systemRoleMenuUpdate = params => put('/platform/system/role/menu/update',params);