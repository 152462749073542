<template>
    <el-container class="html">
        <el-aside class="el-aside" :width="width" :class="isCollapse?'el-aside-old':'el-aside-new'" >
            <div class="logo">{{isPlatform ? '福工惠企业会员管理系统' : '福工惠平台管理系统'}} </div>
            <Menu></Menu>
        </el-aside>
        <el-container class="container">
            <el-header height="100px" class="header">
                <Header :isCollapse="isCollapse"></Header>
                <IndexNav></IndexNav>
            </el-header>
            <el-main>
                <router-view />
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import Menu from '@/pages/menu/menu.vue';
    import Header from '@/pages/header/header.vue';
    import IndexNav from '@/pages/indexNav/indexNav.vue';
    export default {
        name: "index",
        components: {
            Menu,
            Header,
            IndexNav
        },
        data(){
            return{
                width: '240px',
                isCollapse: false,  //导航菜单展开，收缩
                isPlatform:false    // 用来判断是平台还是企业会员  true为企业会员  false为平台
            }
        },
        watch:{
            isCollapse(valueNew){
                console.log(valueNew)
                var that = this;
                if(valueNew){
                    that.width = '64px'
                }else {
                    that.width = '240px'
                }
            }
        },
        mounted () {
            this.isPlatform = this.$store.state.index.isplatform;
            if(this.isPlatform){
                document.title = '福工惠企业会员管理系统'
            }else{
                document.title = '福工惠平台管理系统'
            }
            this.$root.eventHub.$on('changeDate', this.changeDate)
        },
        methods:{
            changeDate(){
                this.isCollapse = !this.isCollapse
            }
        }
    }
</script>

<style scoped lang="scss">
    .html{
        height: 100vh;
        overflow-y: hidden;
    }
    .logo{
        font-size: 18px;
        color: hsla(0,0%,100%,.8);
        line-height: 60px;
		height: 60px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
        text-align: center;
        background-color: #20222a;
		overflow:hidden;
    }
    .el-aside{
        overflow: hidden;
    }
    .container{
        background-color: #f0f2f5;
    }
    .header{
        background-color: #ffffff;
    }
    .el-main{
        padding: 10px !important;
    }
</style>