<template>
    <div class="el-menu-vertical-demo">
        <el-menu
            @select="handleSelect"
            background-color="#20222a"
            text-color="#fff"
            :collapse="isCollapse"
            active-text-color="#409EFF"
            :default-active="subMenuIndex"
            :unique-opened="true"
        >
            <el-submenu :index="(index + 1).toString()" v-for="(item,index) in menu_list" :key="index">
                <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>{{item.name}}</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item
                            :index="(index + 1).toString() + '-' + (indexc + 1).toString()"
                            v-for="(itemc,indexc) in item.children" :key="indexc"
                            @click.native="openMenu(itemc.url,itemc.name)"
                    >
                        <i class="el-icon-location"></i>
                        <span>{{itemc.name}}</span>
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
        </el-menu>
    </div>
</template>

<script>
    import { menuNavList,platFormNavList } from '@/api/system'
    export default {
        name: "navigationMenu",
        data(){
            return{
                isCollapse: false,
                menu_list: [],
                subMenuIndex: this.$store.state.index.SubMenuIndex,
                isPlatform:false                     // 用来判断是平台还是企业会员  true为企业会员  false为平台
            }
        },
      
        mounted () {
            // this.headPortrait = this.$store.state.user.userInfo.headPortrait;
            let that = this;
            that.isPlatform = that.$store.state.index.isplatform;
            that.$root.eventHub.$on('changeDate', that.change)
            if(that.isPlatform){        //  企业会员管理左侧菜单
                platFormNavList().then(res => {
                    this.menu_list = res.data;
                    this.$store.commit("setMenu",res.data);
                })
            }else{                      //  平台管理左侧菜单
                menuNavList().then(res => {
                    this.menu_list = res.data;
                    this.$store.commit("setMenu",res.data);
                })
            }

            this.$root.eventHub.$on('changeSubMenuIndex', (n)=>{
                this.subMenuIndex = n;
            })

        },
        methods: {
            change(){
                let that= this;
                that.isCollapse = !that.isCollapse
            },
            handleSelect(key) {
                this.$store.commit("setSubMenuIndex",key);
                if(key != '0'){
                    let indexA = key.substr(0, key.indexOf("-"));
                    let indexB = key.substring(key.lastIndexOf("-") + 1, key.length);
                    this.$store.commit("setMenuSubButtons",this.$store.state.index.menu[indexA - 1].children[indexB - 1].children);
                }
            },
            openMenu(url,name){
                if(url != this.$store.state.index.router){
                    this.$router.push(url)
                    let tableTabs = this.$store.state.index.tableTabs;
                    let data = {
                        title: name,
                        name: '0',
                        url: url,
                        index: this.$store.state.index.SubMenuIndex
                    }
                    let exist = false;
                    tableTabs.forEach(item => {
                        if(item.title == data.title){
                            data.name = item.name;
                            exist = true;
                        }
                    })
                    if(!exist){
                        tableTabs.forEach((item,index) => {
                            if(index + 1 == tableTabs.length){
                                data.name = (item.name * 1 + 1).toString()
                            }
                        })
                    }
                    this.$root.eventHub.$emit('changeTableTabs', {type: exist,data});
                }
            }
        }
    }
</script>

<style>
    .el-menu-vertical-demo {
        width: 100%;
        height: calc(100% - 60px);
        background-color: #20222a;
        overflow-x: hidden;
        overflow: auto;
    }
    .el-menu-vertical-demo::-webkit-scrollbar{
        display: none;
    }
    .el-menu-vertical-demo  .el-menu{
        border: none !important;
    }
    .el-menu-item-group .el-menu-item-group__title{
        padding: 0;
    }
</style>