import {  get,post,put } from './axios'
/* 登陆 */
export const login = params => post('/web/login', params);
/* 获取操作手册 */
export const operation = params => get('/web/operation', params);

/* 导航菜单列表 */
export const menuList = params => get('/web/index/menu',params);

/* 个人信息 */
export const userinfo = params => get('/web/index/user/info',params);

// 修改个人信息
export const useredit = params => put('/web/index/user/edit',params);

// 修改个人信息
export const userpassword = params => put('/web/index/user/password/update',params);


//等出
export const logout = params => get('/web/index/logout',params);


// --------------------  企业会员 ----------------
/* 登录 */
export const platformLogin = params => post('/platform/login', params);

/* 用户信息 */
export const platformUserinfo = params => get('/platform/index/user/info',params);
