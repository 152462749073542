import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

// pages
// 登录
import login from '@/pages/login/login';
import loginPlat from '@/pages/login/loginPlatform';
// 首页
import index from '@/pages/index/index';




// view 内容
// index首页
import indexHome from '@/view/index/index';

// system 系统管理
import systemUser from '@/view/system/user';
import systemRole from '@/view/system/role';



export default new Router({
	// mode: 'history',//去掉#号、
	mode: 'hash', //保留#号、
	routes: [{
			path: '/',
			name: 'login',
			component: login,
		}, 
		{
			path: '/loginPlatform',
			name: 'loginPlatform',
			component: loginPlat,
		}, 
		{
			path: '/login',
			name: 'login',
			component: login,
		},
		{
			path: '/',
			component: index,
			redirect: '/index',
			children: [{
					path: 'index',
					name: '首页',
					component: indexHome,
				}, 
				{
					path: '/system/user', //用户管理
					name: '/user',
					component: systemUser
				},
				{
					path: '/index/changePass',
					name: 'changePass',
					component: () => import('@/view/index/changePass')
				},
				{
					path: '/system/role', //角色管理
					name: '/role',
					component: () => import('@/view/system/role')
				}, 
				{
					path: 'settings/indexSettings', //首页管理
					name: '/indexSettings',
					component: () => import('@/view/settings/indexSettings')
				},
				{
					path: 'settings/index/diy', //首页管理
					name: '/indexDiy',
					component: () => import('@/view/settings/diy/indexDiy')
				},
				{
					path: '/settings/bannerSettings', //banner管理
					name: '/bannerSettings',
					component: () => import('@/view/settings/bannerSettings')
				},
				{
					path: '/merchant/list', //商家区管理
					name: '/list',
					component: () => import('@/view/merchant/list')
				},
				{
					path: '/supplier/list', //供应商列表
					name: '/list',
					component: () => import('@/view/supplier/list')
				},
				{
					path: '/supplier/audit', //供应商审核
					name: '/audit',
					component: () => import('@/view/supplier/audit')
				},
				{
					path: '/supplier/account', //供应商对账结算
					name: '/account',
					component: () => import('@/view/supplier/account')
				},
				{
					path: '/supplier/accountXq', //供应商订单详情
					name: '/accountXq',
					component: () => import('@/view/supplier/accountXq')
				},
				{
					path: '/independent/proportionSet', // 分账管理分账比例配置
					name: '/proportionSet',
					component: () => import('@/view/independent/proportionSet')
				},
				{
					path: '/independent/manual', // 分账管理手动分账
					name: '/manual',
					component: () => import('@/view/independent/manual')
				},
				{
					path: '/independent/management', //分账管理对账管理
					name: '/management',
					component: () => import('@/view/independent/management')
				},
				{
					path: '/independent/audit', //分账管理分账审核
					name: '/audit',
					component: () => import('@/view/independent/audit'),
				},
				{
					path: '/member/list', //企业会员列表
					name: '/list',
					component: () => import('@/view/member/list'),
				},
				{
					path: '/member/listLow', //企业会员列表
					name: '/memberlistLow',
					component: () => import('@/view/member/listLow'),
				},
				{
					path: '/member/audit', //企业会员审核
					name: '/audit',
					component: () => import('@/view/member/audit'),
				},
				{
					path: '/member/integral', //企业会员积分管理
					name: '/integral',
					component: () => import('@/view/member/integral'),
				},
				{
					path: '/distribution/list', //分销员列表
					name: '/list',
					component: () => import('@/view/distribution/list'),
				},
				{
					path: '/distribution/listLow', // 分销员下级列表
					name: '/listLow',
					component: () => import('@/view/distribution/listLow'),
				},
				{
					path: '/distribution/rebate', //返佣设置
					name: '/rebate',
					component: () => import('@/view/distribution/rebate'),
				},
				{
					path: '/data/performance', //业绩统计
					name: '/performance',
					component: () => import('@/view/data/performance'),
				},
				{
					path: '/data/indent', // 数据统计
					name: '/indent',
					component: () => import('@/view/data/indent'),
				},
				{
					path: '/data/recharge', //充值统计
					name: '/recharge',
					component: () => import('@/view/data/recharge'),
				},
				{
					path: '/data/system', //流量统计
					name: '/system',
					component: () => import('@/view/data/system'),
				},
				{
					path: '/integral/rechargeAudit', //积分充值审核
					name: '/rechargeAudit',
					component: () => import('@/view/integral/rechargeAudit'),
				},
				{
					path: '/integral/withdrawAudit', //积分提现审核
					name: '/withdrawAudit',
					component: () => import('@/view/integral/withdrawAudit'),
				},
				{
					path: '/integral/scaleSet', 	//积分比例配置
					name: '/scaleSet',
					component: () => import('@/view/integral/scaleSet'),
				},
				{
					path: '/integral/accountSet', 	//积分结算配置
					name: '/accountSet',
					component: () => import('@/view/integral/accountSet'),
				},



				//	企业会员管理
				{
					path: '/platform/employee/list', 	//员工列表
					name: '/employee',
					component: () => import('@/view/platform/employee/list'),
				},
				{
					path: '/platform/memberdata/integral/list', 	//积分统计
					name: '/memberdata',
					component: () => import('@/view/platform/memberdata/list'),
				},
				{
					path: '/platform/acc/integralrecharge', 	//积分统计
					name: '/integralrecharge',
					component: () => import('@/view/platform/acc/integralrecharge'),
				},
				{
					path: '/platform/acc/pointsout/balance', 	// 积分发放
					name: '/pointsoutBalance',
					component: () => import('@/view/platform/acc/pointsout'),
				},
				{
					path: '/qy/settings/bannerSettings', 		// banner管理
					name: '/qyBannerSettings',
					component: () => import('@/view/platform/settings/bannerSettings'),
				},
				{
					path: '/platform/system/role', 				// 角色管理
					name: '/systemRole',
					component: () => import('@/view/platform/system/role'),
				},
				{
					path: '/platform/system/user', 				// 用户管理
					name: '/systemUser',
					component: () => import('@/view/platform/system/user'),
				},
			]
		}
	]
})