<template>
	<div>
		<span v-if="moduleKey == 'tools'">{{ item.toolsName }}</span>
		<span v-if="moduleKey == 'articles'">{{ item.toolsName }}</span>
		<span v-if="moduleKey == 'button'">{{ item.toolsName }}</span>
	</div>
</template>

<script>
export default {
	name: 'moduleText',
	props: {
		'item':{type:Object},
		'moduleKey':{type:String},
	},
	created(){},
	computed: {}
}
</script>
