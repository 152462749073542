<template>
    <div class="indexNav">
        <el-tabs v-model="editableTabsValue"
                 type="card"
                 @tab-click="tabClick"
                 @tab-remove="removeTab">
            <el-tab-pane
                    class="tab_after"
                    v-for="(item, index) in editableTabs"
                    :key="item.name"
                    :label="item.title"
                    :name="item.name"
                    :closable="index != 0"
            >
                {{item.content}}
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    export default {
        name: "indexNav",
        data() {
            return {
                editableTabsValue: '0',
                editableTabs: [],
            };
        },
        mounted() {
            let tableTabs = this.$store.state.index.tableTabs;
            this.editableTabs = tableTabs;
            let router = this.$store.state.index.router
            this.editableTabs.forEach(item => {
                if(item.url == router){
                    this.editableTabsValue = item.name;
                }
            })

            this.$root.eventHub.$on('changeTableTabs', (n)=>{
                console.log(n)
                this.editableTabsValue = n.data.name;
                let result = this.editableTabs.find(item =>{
                    return item.name == n.data.name
                });
                // console.log(result)
                if(result == undefined){
                    this.editableTabs.push(n.data);
                    this.$store.commit("setTableTabs",this.editableTabs);
                }
            })
        },
        methods: {
            tabClick(tab){
                if(this.editableTabs[tab.index].url != this.$store.state.index.router){
                    this.$router.push(this.editableTabs[tab.index].url);
                    this.editableTabsValue = this.editableTabs[tab.index].name;
                    this.$store.commit("setSubMenuIndex",this.editableTabs[tab.index].index);
                    this.$root.eventHub.$emit('changeSubMenuIndex', this.editableTabs[tab.index].index);
                }
            },
            removeTab(targetName) {
                // console.log('targetName', targetName)
                // console.log('this.editableTabs', this.editableTabs)
                // console.log('this.editableTabsValue', this.editableTabsValue)
                // this.editableTabs.splice(targetName - 1,1)
                // this.editableTabsValue = this.editableTabs[this.editableTabs.length - 1].name;
                // this.editableTabsValue = (this.editableTabsValue * 1 - 1).toString()
                if (targetName != this.editableTabsValue) {
                    this.editableTabs.forEach((item, index) => {
                        if (item.name === targetName) {
                            this.editableTabs.splice(index,1)
                        }
                    })
                } else {
                    this.editableTabs.forEach((item, index) => {
                        if (item.name === targetName) {
                            this.editableTabs.splice(index,1)
                            this.editableTabsValue = this.editableTabs[index - 1].name;
                            this.$root.eventHub.$emit('changeSubMenuIndex', this.editableTabs[index - 1].index);
                            this.$router.push(this.editableTabs[index - 1].url)
                        }
                    })
                }
            }
        }
    };
</script>

<style scoped>
    .indexNav{
        height: 40px;
    }
    .el-tabs--card>.el-tabs__header .el-tabs__nav{
        border: none;
    }
    .indexNav >>> .el-tabs__header{
        margin-bottom: 0;
    }
    .indexNav >>> .el-tabs--card>.el-tabs__header .el-tabs__nav{
        border: none;
    }
    .indexNav >>> .el-tabs--card>.el-tabs__header .el-tabs__item{
        border: none;
    }
    .indexNav >>> .el-tabs--card>.el-tabs__header .is-active{
        position: relative;
    }
    .indexNav >>> .el-tabs--card>.el-tabs__header .is-active:after{
        content: "";
        width: 100%;
        height: 2px;
        background: #409EFF;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .indexNav >>> .el-tabs--card>.el-tabs__header{
        border: none;
    }
</style>