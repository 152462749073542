import Vue from "vue"
import App from "./App.vue"
import Vuex from "vuex"
import store from "./store"
import AvueUeditor from "avue-plugin-ueditor"
Vue.use(AvueUeditor)
Vue.use(Vuex)
// const imageUrl='https://jfsc.java.minshangkeji.com.cn'
import router from "./router"
import VueRouter from "vue-router"
Vue.use(VueRouter)
import Viewer from "v-viewer"
import "viewerjs/dist/viewer.css"

//Vue.use(Viewer) 默认配置写法
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999,
  },
})

Date.prototype.Format = function (fmt) {
  // author: meizz
  var o = {
    "M+": this.getMonth() + 1, // 月份
    "d+": this.getDate(), // 日
    "h+": this.getHours(), // 小时
    "m+": this.getMinutes(), // 分
    "s+": this.getSeconds(), // 秒
    "q+": Math.floor((this.getMonth() + 3) / 3), // 季度
    S: this.getMilliseconds(), // 毫秒
  }
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    )
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      )
  return fmt
}

import ElementUI from "element-ui"
import "element-ui/lib/theme-chalk/index.css"
Vue.use(ElementUI)

// Avue引用
import Avue from "@smallwei/avue"
import "@smallwei/avue/lib/index.css"
Vue.use(Avue)

Vue.config.productionTip = false

Vue.prototype.url = "https://jfsc.java.minshangkeji.com.cn"

import global from "@/utils/global"
Vue.prototype.global = global

import keyVerify from "@/utils/keyVerify"
Vue.prototype.keyVerify = keyVerify

// diy页面
/***标题***/
import moduleTitle from "./view/settings/diy/module/title.vue"
Vue.component("moduleTitle", moduleTitle)

import util from "@/view/settings/diy/util/util"
Vue.prototype.$util = util

new Vue({
  router,
  store,
  data: {
    eventHub: new Vue(), //vue 全局事件(eventBus)设置
  },
  render: (h) => h(App),
}).$mount("#app")
