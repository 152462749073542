// import $ from "jquery";
// import api from '@/api/api.js'
import {Message} from 'element-ui'

// 滚动条滚动动画
function scrollTop(dom, number = 0, time){
	if (!time) {
		dom.scrollTop = number
		return number
	}
	const spacingTime = 20 // 设置循环的间隔时间  值越小消耗性能越高
	let spacingInex = time / spacingTime // 计算循环的次数
	let nowTop = dom.scrollTop // 获取当前滚动条位置
	let everTop = (number - nowTop) / spacingInex // 计算每次滑动的距离
	let scrollTimer = setInterval(() => {
		if (spacingInex > 0) {
			spacingInex--
			this.scrollTop(dom, nowTop += everTop)
		} else {
			clearInterval(scrollTimer) // 清除计时器
		}
	}, spacingTime)
}

// 	左侧模块图片
function icon(img){
	return require('../assets/'+img);
}

//	打开对应模块属性项
function openAttr(arr,index){
	arr.forEach((res,i)=>{
		if(index==i){ res.listShow = true;}else{ res.listShow = false;}
	})
}

//	展开
function openMore(arr,index){
	arr[index].listShow = arr[index].listShow? false : true;
}

//	添加模块项
function addItem(arr,item){
	let arr2 = JSON.stringify(item);
	arr.push(JSON.parse(arr2));
}

//	提示
function tips(text,type='success',time=1500){
	Message({type:type,message:text,center:true,duration:time});
}


export default{
	scrollTop,
	icon,
	openAttr,
	tips,
	openMore,
	addItem
}