 /**
 * 全局正整数
 */
var key = function(e) {
    let that = this;
    var keynum = window.event ? e.keyCode : e.which;   //获取键盘码
    if (keynum ==189|| keynum==190||keynum == 109 ||keynum == 110 ) {
        that.$message.warning('禁止输入小数以及负数')
        e.target.value = ''
    }
}
export default key;