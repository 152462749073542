import Vue from 'vue'
import Vuex from 'vuex'
import index from './modules/index'
import user from './modules/user'
import system from './modules/system'
Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        index,
        user,
        system
    }
})



export default store


