<template>
    <div class="header">
        <div class="fold" :class="isCollapse?'rotate180':'rotate0'" @click="fold">
            <i class="el-icon-s-fold"></i>
        </div>
        <div>
            <el-dropdown trigger="click"  >
                  <div class="el-dropdown-link">
                      <span style="margin-right:10px;">你好，{{fullName}}</span>
                      <avue-avatar :src="headPortrait"></avue-avatar>
                      <i class="el-icon-caret-bottom el-icon--right"></i>
                  </div>
                  <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="/index">首页</el-dropdown-item>
                        <el-button class="drop_button" type="default" style="display:block;border:none;" @click="changePass">修改密码</el-button>
                        <el-button class="drop_button" type="default" style="display:block;border:none;" @click="handleCommand">退出登录</el-button>
                        <!-- <el-dropdown-item :divided="true" @click="handleCommand">退出登录</el-dropdown-item> -->
                         <!-- :command="isPlatform ? '/loginPlatform' : '/login'" -->
                  </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
import { userinfo,logout,platformUserinfo } from "@/api";
import { imageUrl } from '@/config/public';
export default {
    name: "header.vue",
    props:{
        isCollapse: Boolean
    },
    data(){
        return{
            image_Url: imageUrl,
            headPortrait: '',
            fullName: '',
            isPlatform:false                     // 用来判断是平台还是企业会员  true为企业会员  false为平台
        }
    },
    mounted() {
        this.isPlatform = this.$store.state.index.isplatform;

        if(this.isPlatform){            //  企业会员用户信息
            platformUserinfo().then(res => {
                if(res.code === 200){
                    this.headPortrait = res.data.headPortrait;
                    this.fullName = res.data.fullName;
                    this.$store.commit("setUserInfo",res.data);
                }
            })
        }else{                          //  平台用户信息
            userinfo().then(res => {
                if(res.code === 200){
                    this.headPortrait = res.data.headPortrait;
                    this.fullName = res.data.fullName;
                    this.$store.commit("setUserInfo",res.data);
                }
            })
        }

        this.$root.eventHub.$on('changeUserInfo', (n)=>{
            this.headPortrait = this.$store.state.user.userInfo.headPortrait;
        })
    },
    methods:{
        // 修改密码
        changePass(){
            this.$router.push({name:'changePass'});
        },
        fold(){
            this.$root.eventHub.$emit('changeDate', null)
        },
        showPassword(){
            this.showPass = true;
        },
        handleCommand() {
            let that = this;
            that.$confirm(`退出系统, 是否继续?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                callback: action => {
                    if(action == 'confirm'){
                        logout().then(res => {
                            if(res.code === 200){
                                that.$store.commit('setSubMenuIndex',null)
                                that.$store.commit('setUserInfo',null)
                                that.$store.commit('setToken',null)

                                // store.index
                                that.$store.commit('setMenu',[])
                                that.$store.commit('setRouter','')
                                that.$store.commit('setSubMenuIndex','')

                                that.$store.commit('setMenuSubButtons',[])

                                that.$store.commit('setTableTabs',[{
                                    title: '首页',
                                    name: '0',
                                    url: '/index',
                                    index: '0'
                                }])

                                // store.system
                                that.$store.commit('setRole',[])

                                //store.user
                                that.$store.commit('setToken','')
                                that.$store.commit('setUserInfo',{})

                                if(that.isPlatform){
                                    that.$router.push('/loginPlatform');
                                }else{
                                    that.$router.push('/login');
                                }
                            }
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.header{
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.fold{
    font-size: 30px;
}
.rotate180{
    transition: .5s;
    transform:rotate(180deg)
}
.rotate0{
    transition: .5s;
    transform:rotate(0deg)
}
.el-icon--right{
    margin-left: 20px;
}
.drop_button{
    display: block;
    width: 100%;
    border: none;
    margin: 0 !important;
}
</style>