<template>
	<div class="main-content">
		<div class="meunTop">
			<div class="meunLine"></div>
			<div class="meunTitle">用户管理</div>
		</div>
		<div class="flex">
			<div>
				<el-form class="search" label-position='left'  size="large">
					<el-form-item style="margin-left:20px; display:flex;" label="手机号：">
						<el-input  :maxlength="50" style="width:300px;" v-model="phone" placeholder="请输入手机号"  clearable></el-input>
					</el-form-item>
					<el-form-item style="margin-left:20px;">
						<el-button  type="primary" @click="seachSumbit()">搜索</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-button @click="addshow=true;addtype=1">添加</el-button>
		</div>
		<div class="table-content">
			<el-table :cell-style="{ textAlign: 'center' }" :header-cell-style="{textAlign: 'center',backgroundColor:'#F5F7FA',color:'#333'}" :border="true" :data="data" style="width: 100%">
				<el-table-column fixed prop="headPortrait" label="头像" width="200">
					<template slot-scope="scope">
						<avue-avatar :src="scope.row.headPortrait"></avue-avatar>
					</template>
				</el-table-column>
				<el-table-column prop="fullName" label="用户姓名" width="200">
				</el-table-column>
				<el-table-column prop="phone" label="后台账号" width="250">
				</el-table-column>
				<el-table-column prop="state" label="状态" width="250">
					<template slot-scope="scope">
						<div style="display: flex;justify-content: center;">
							<div style="display: flex;">
								<p >{{scope.row.status == 0 ? '启用' : '禁止'}} </p>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="fullName" label="角色名称" width="250">
				</el-table-column>
				<el-table-column prop="createTime" label="创建时间" width="250">
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="200">
					<template style="display: flex;" slot-scope="scope">
						<el-button @click.native.prevent="addRole(scope.$index, data)" type="text" size="small">
							添加角色
						</el-button>
						<el-button @click.native.prevent="updataclick(scope.$index, data)" type="text" size="small">
							编辑
						</el-button>
						<el-button @click.native.prevent="delUser(scope.$index, data)" type="text" size="small">
							删除
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>

		<!-- 新增编辑用户弹窗 -->
		<el-dialog :visible.sync="addshow" width="700px" :before-close="handleClose" title="账号管理">
			<el-form class="form_box" label-position='left' ref="form" :hide-required-asterisk='true' :model="sizeForm" size="small">
				<el-form-item :required='true' >
					<template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:120px;text-align:center;"><span style="color:red">*</span>用户姓名：</p>
							<el-input :maxlength="20" v-model="sizeForm.fullName" clearable placeholder="请输入用户姓名"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item :required='true'>
					<template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:120px;text-align:center;"><span style="color:red">*</span>后台账号：</p>
							<el-input  type="number" :maxlength="11" v-model="sizeForm.phone" oninput="if(value.length>11)value=value.slice(0,11)" clearable  placeholder="请输入手机号"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item :required='true'>
					<template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:120px;text-align:center;"><span style="color:red" v-if="addtype==1">*</span>后台密码：</p>
							<el-input :maxlength="20" v-model="sizeForm.password" clearable  placeholder="请输入后台密码"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item :required='true'>
					<template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:120px;text-align:center;">地区：</p>
							<el-input type="text" :maxlength="20" v-model="sizeForm.area" clearable placeholder="请输入地区"></el-input>
						</div>
					</template>
				</el-form-item>
				<el-form-item label="">
					<template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:120px;text-align:center;">用户头像：</p>
							<el-upload class="avatar-uploader" action="api/web/tool/file/upload" :headers="headers" :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
								<img v-if="sizeForm.headPortrait" :src="sizeForm.headPortrait" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon photo"></i>
							</el-upload>
						</div>
					</template>
				</el-form-item>
				<el-form-item :required='true'>
					<template slot-scope="scope">
						<div style="display:flex;" :src="scope.row">
							<p style="min-width:120px;text-align:center;"><span style="color:red">*</span>启用状态：</p>
							<el-select  v-model="sizeForm.userStatus"  placeholder ="启用状态">
								<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value" > </el-option>
							</el-select>
						</div>
					</template>
				</el-form-item>
				
				<el-form-item size="large" style="text-align:center;margin-top:50px;">
					<el-button @click='handleClose'>取消</el-button>
					<el-button v-if="addtype==1" type="primary" @click="onSubmit">添加</el-button>
					<el-button v-if="addtype==2" type="primary" @click="onUpdata">修改</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>

		<!-- 角色赋权 -->
		<el-dialog :visible.sync="roleShow" width="500px" :before-close="handleClose" title="用户角色">
			<el-form class="form_box" label-position='left' ref="form" :hide-required-asterisk='true' :model="sizeForm" size="small">
				<el-form-item label="用户角色：">
					<el-select multiple v-model="roleIds" placeholder="请选择角色权限">
						<el-option v-for="item in roleIdsoption" :key="item.value" :label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item size="large" style="text-align:center;margin-top:50px;">
					<el-button @click='handleClose'>取消</el-button>
					<el-button type="primary" @click="roleUpdate">确定</el-button>
				</el-form-item>
				
			</el-form>
		</el-dialog>

		<div class="">
			<el-pagination @current-change="handleCurrentChange" :current-page.sync="currentPage"
				:page-count="pageCount" :page-size="pageSize" layout="total, prev, pager, next" :total="total">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import {
		userlist,
		userDelete,
		roleMenuView,
		roleList,
		userRole,
		userAdd,
		roleUpdate,
		userUpdate,
		roleView
	} from "@/api/system/index";
	import {
		imageUrl,
	} from '@/config/public';
	import {
		mapActions
	} from 'vuex'

	export default {
		name: "role",
		data() {
			return {
				fileList:[],
				roleIdsoption: [],
				headers: {
					Authorization: this.$store.state.user.Token
				},
				statusOptions: [{label: '启用',value: 0},{label: '禁用',value: 1}],
				dialogVisible: false,
				data: [],
				addshow: false,
				sizeForm: {
					fullName: '',
					headPortrait: '',
					password: '',
					phone: '',
					area: '',
					id: '',
					userStatus:0,
				},
				roles:[],
				roleShow:false,
				page: 1,
				pageSize: 10,
				roleIds:[],
				roleId:'',				// 添加角色人的id
				fullName: '',
				phone: '',
				username: '',
				addtype: '',
				total: 0, //总数据
				currentPage: 0, //当前页页数
				pageCount: 0, //总页数
				image_Url: imageUrl
			};
		},
		mounted() {
			let that = this;
			that.Buttons = that.$store.state.index.menuSubButtons

			that.user_list(); //用户管理-列表
			that.userRole(); //用户管理-角色列表

			that.$root.eventHub.$on('changeRole', (n) => {
				that.dialogVisible_role = n;
			})
			that.$root.eventHub.$on('changeEdit', (n) => {
				that.dialogVisible_edit = n;
			})
			that.$root.eventHub.$on('changeAdd', (n) => {
				that.dialogVisible_add = n.state;
				if (n.type === 'add') {
					that.user_list();
				}
			})
			that.$root.eventHub.$on('changeView', (n) => {
				that.dialogVisible_view = n;
			})
		},
		created() {
		},
		methods: {
			// 获取数据列表
			user_list() {
				userlist({
					page: this.page,
					size: this.pageSize,
					phone: this.phone,
				}).then(res => {
					if(res.code == 200){
						this.data = res.data.records;
						this.total = res.data.total;
						this.currentPage = res.data.current;
						this.pageCount = res.data.pages;
						if (res.data.current > res.data.pages && res.data.pages != 0) {
							this.page = res.data.pages;
							this.user_list();
						}
					}else{
						this.$message.error(res.msg);
					}
				}).catch(err => {
					console.log(err.msg)
				})
			},

			userRole() {
				userRole().then(res => {
					if (res.code == 200) {
						res.data.forEach((item, index) => {
							item.label = item.name
							item.value = item.id
						})
						this.roleIdsoption = res.data
					}else{
						this.$message.error(res.msg);
					}
				}).catch(e => {
					this.$message.error(e.msg);
				})
			},

			// 分页
			handleCurrentChange(val) {
				// console.log(`当前页: ${val}`);
				this.page = val;
				this.user_list();
			},

			//搜索提交
			seachSumbit(){
				this.page = 1
				this.user_list();
			},

			// 编辑用户
			updataclick(i,tab){
				console.log(tab[i]);
				let that = this;
				that.sizeForm = {
                   	fullName: tab[i].fullName,
					headPortrait: tab[i].headPortrait,
					phone: tab[i].phone,
					area: tab[i].area,
					userStatus: tab[i].status ,
					id: tab[i].id ,
				}
				that.addtype = 2
				that.addshow = true
			},

			// 删除用户
			delUser(cur, tab) {
				this.$confirm('您确定要删除【'+ tab[cur].fullName +'】用户吗？删除后不可恢复！', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					userDelete(tab[cur].id).then(res => {
						this.page = 1;
						this.user_list();
						this.$message({
							type: 'success',
							message: res.msg
						});
					})
				}).catch(() => {});
			},

			//图片上传
			handleAvatarSuccess(res, file) {
				if(res.code == 200){
					this.sizeForm.headPortrait = res.data.path;
				}else{
					this.$message.error(res.msg);
				}
			},
			//图片验证
			beforeAvatarUpload(file) {
				const isJPG = (file.type === 'image/jpeg' || file.type === 'image/png');
				// const isLt2M = file.size / 1024 / 1024 < 2;
				if (!isJPG) {
					this.$message.error('Banner图片只能是 JPG 或 PNG 格式!');
				}
				// if (!isLt2M) {
				// 	this.$message.error('上传头像图片大小不能超过 2MB!');
				// }
				// return isJPG && isLt2M;
				return isJPG
			},

			...mapActions(['getRole']),
			
			//用户管理-列表
			handleClose() {
				this.sizeForm = {
					fullName: '',
					headPortrait: '',
					password: '',
					phone: '',
					area: '',
					userStatus: 0,
					id:''
				}
				this.roles = [];
				this.roleId = '';
				this.addshow = false;
				this.roleShow = false;		
			},

			// 添加用户
			onSubmit() {
				let that = this;
				if(that.sizeForm.fullName == ''){
					that.$message.warning('用户姓名不能为空');
				}else if(that.sizeForm.phone == ''){
					that.$message.warning('后台账号不能为空');
				}else if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.sizeForm.phone))){
					that.$message.warning('后台账号手机号不合法');
				}else if(that.sizeForm.password == ''){
					that.$message.warning('密码不能为空');
				}else{
					userAdd({
						fullName: that.sizeForm.fullName,
						headPortrait: that.sizeForm.headPortrait,
						password: that.sizeForm.password,
						phone: that.sizeForm.phone,
						area: that.sizeForm.area,
						status: that.sizeForm.userStatus ,
					}).then(res => {
						if (res.code == 200) {
							that.sizeForm = {
								fullName: '',
								headPortrait: '',
								password: '',
								phone: '',
								area: '',
								userStatus: 0,
								id:''
							}
							that.page = 1;
							that.user_list();
							that.addshow = false
							that.$message.success(res.msg);
						} else {
							that.$message.error(res.msg);
						}
					}).catch(e => {
						that.$message({
							message: e.msg,
							type: 'error'
						});
					})
				}
			},

			// 编辑用户数据
			onUpdata(){
				let that = this;
				if(that.sizeForm.fullName == ''){
					that.$message.error('用户姓名不能为空');
				}else if(that.sizeForm.phone == ''){
					that.$message.error('后台账号不能为空');
				}else if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(that.sizeForm.phone))){
					that.$message.warning('后台账号手机号不合法');
				}else{
					userUpdate({
						fullName: that.sizeForm.fullName,
						headPortrait: that.sizeForm.headPortrait,
						phone: that.sizeForm.phone,
						area: that.sizeForm.area,
						status: that.sizeForm.userStatus,
						id: that.sizeForm.id
					}).then(res=>{
						if (res.code == 200) {
							that.sizeForm = {
								fullName: '',
								headPortrait: '',
								password: '',
								phone: '',
								area: '',
								userStatus: 0,
							}
							that.page = 1;
							that.user_list();
							that.addshow = false
							that.$message.success(res.msg);
						} else {
							that.$message.error(res.msg);
						}
					}).catch(e=>{
						that.$message({
							message: e.msg,
							type: 'error'
						});
					})
				}
			},

			// 角色赋权
			addRole(i,tab){
				let that = this;
				that.roleShow = true;
				that.roleId = tab[i].id;
				roleView(tab[i].id).then(res => {
					if(res.code == 200){
						that.roleIds = res.data;
					}
                })
			},

			// 添加角色
			roleUpdate(){
				let that = this;
				roleUpdate({
					id: that.roleId,
					roleIds: that.roleIds.join(','),
				}).then(res=>{
					if (res.code == 200) {
						that.roleId = '';
						that.roleIds = [];
						that.page = 1;
						that.user_list();
						that.roleShow = false
						that.$message.success(res.msg);
					} else {
						that.$message.error(res.msg);
					}
				}).catch(e=>{
					that.$message({
						message: e.msg,
						type: 'error'
					});
				})

			},
		}
	}
</script>

<style scoped>
	.table-content {margin-top: 20px;}
	.active {color: #049EC4;}
	.form_box{padding:0 50px;}
	.avatar{width:150px;}
	.photo{width:150px;height:150px;border:1px solid #eee;display:flex;align-items: center;justify-content:center;font-size:40px;color:#ccc;}
	.flex{display:flex;align-items:center;justify-content:space-between;}
	
	.meunTop{display:flex;align-items:center;margin-bottom:20px;}
	.meunLine{width:3px;height:20px;background-color:#FFA218;}
	.meunTitle{font-size:20px;margin-left:10px;}
</style>
