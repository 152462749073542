<template>
	<div class="main-content">
		<div class="info_box">{{isPlatform ? '欢迎使用福工惠企业会员管理系统！' : '欢迎使用福工惠平台管理系统！'}}</div>
	</div>
</template>

<script>
	export default {
		name: 'index',
		data() {
			return {
                isPlatform:false                     // 用来判断是平台还是企业会员  true为企业会员  false为平台
			};
		},
		mounted(){
            this.isPlatform = this.$store.state.index.isplatform;
		},
	};
</script>

<style lang="scss">
	.info_box{
		text-align:center;
		font-size:18px;
		margin-top:40px;
	}
</style>
